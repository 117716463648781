// eslint-disable-next-line import/prefer-default-export
export const listForms = /* GraphQL */ `
    query ListForms(
        $id: ID
        $filter: ModelFormFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listForms(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                slug
                name
                type
                instruments {
                    items {
                        id
                    }
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const updateForm = /* GraphQL */ `
    mutation UpdateForm(
        $input: UpdateFormInput!
        $condition: ModelFormConditionInput
    ) {
        updateForm(input: $input, condition: $condition) {
            id
            slug
            name
            type
            instruments {
                items {
                    id
                    name
                    state {
                        enabled
                    }
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
        }
    }
`;

export const deleteForm = /* GraphQL */ `
    mutation DeleteForm(
        $input: DeleteFormInput!
        $condition: ModelFormConditionInput
    ) {
        deleteForm(input: $input, condition: $condition) {
            id
            slug
            name
            type
            instruments {
                items {
                    id
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
        }
    }
`;

export const onCreateForm = /* GraphQL */ `
    subscription OnCreateForm {
        onCreateForm {
            id
            slug
            name
            type
            instruments {
                items {
                    id
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
        }
    }
`;
export const onUpdateForm = /* GraphQL */ `
    subscription OnUpdateForm {
        onUpdateForm {
            id
            slug
            name
            type
            instruments {
                items {
                    id
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
        }
    }
`;
export const onDeleteForm = /* GraphQL */ `
    subscription OnDeleteForm {
        onDeleteForm {
            id
            slug
            name
            type
            instruments {
                items {
                    id
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
        }
    }
`;
