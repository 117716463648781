/* eslint-disable no-await-in-loop */
import {API, graphqlOperation} from 'aws-amplify';
import {batchProcessWithRetry, SwalData} from '@/graphql/cascade/batch';

export const cascadeConfirmDeleteOptions = {
    confirm: {
        text: `
          <p class='mb-0'>Deleting this Form may have unwanted side effects.</p>
          <p class='font-small-3 text-danger'>
            Applications and Instruments currently associated with this form will have their association removed.<br/>
            This may effect applications, scoring, selections, acceptance, as well as reports.
          </p>
          <strong>Be very careful when performing this action.</strong>
        `,
        shouldParse: true
    },
    confirmed: { text: 'Form was successfully deleted.' }
}

export async function cascadeDeleteForm(formId, swalCallback) {
    try {
        /** Update Instrument records associated to this Form **/
        const instruments = await listInstruments(formId, swalCallback)
        await batchProcessWithRetry(instruments, async (instrument) => {
            await API.graphql(graphqlOperation(updateInstrumentMutation, { input: { id: instrument.id, formInstrumentsId: null }}));
        }, { title: 'Updating Instruments', callback: swalCallback })

        /** Update Application records associated to this Instrument **/
        const applications = await listApplications(formId, swalCallback)
        await batchProcessWithRetry(applications, async (application) => {
            await API.graphql(graphqlOperation(updateApplicationMutation, { input: { id: application.id, applicationFormId: null }}));
        }, { title: 'Updating Applications', callback: swalCallback })

        /** Delete Form **/
        await deleteForm(formId, swalCallback)
    }
    catch(e) {
        console.error(e)
    }
}

/** Helper Functions **/
async function deleteForm(formId, swalCallback) {
    const title = 'Deleting Form'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    await API.graphql(graphqlOperation(deleteFormMutation, { input: { id: formId }}));
    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}

async function listApplications(formId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { applicationFormId: { eq: formId } } }
            const response = await API.graphql(graphqlOperation(listApplicationsQuery, input));
            items.push(...response.data.listApplications.items)
            nextToken = response.data.listApplications.nextToken;
            swalCallback(new SwalData('Loading Applications', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}
async function listInstruments(formId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { formInstrumentsId: { eq: formId } } }
            const response = await API.graphql(graphqlOperation(listInstrumentsQuery, input));
            items.push(...response.data.listInstruments.items)
            nextToken = response.data.listInstruments.nextToken;
            swalCallback(new SwalData('Loading Instruments', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}

/** Queries & Mutations **/
const listInstrumentsQuery = /* GraphQL */ `
    query ListInstruments(
        $id: ID
        $filter: ModelInstrumentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInstruments(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                formInstrumentsId
            }
            nextToken
        }
    }
`;

const updateInstrumentMutation = /* GraphQL */ `
    mutation UpdateInstrument(
        $input: UpdateInstrumentInput!
        $condition: ModelInstrumentConditionInput
    ) {
        updateInstrument(input: $input, condition: $condition) {
            id
            formInstrumentsId
        }
    }
`;

const listApplicationsQuery = /* GraphQL */ `
    query ListApplications(
        $id: ID
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listApplications(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                applicationFormId
            }
            nextToken
        }
    }
`;

const updateApplicationMutation = /* GraphQL */ `
    mutation UpdateApplication(
        $input: UpdateApplicationInput!
        $condition: ModelApplicationConditionInput
    ) {
        updateApplication(input: $input, condition: $condition) {
            id
            applicationFormId
        }
    }
`;

const deleteFormMutation = /* GraphQL */ `
    mutation DeleteForm(
        $input: DeleteFormInput!
        $condition: ModelFormConditionInput
    ) {
        deleteForm(input: $input, condition: $condition) {
            id
        }
    }
`;
