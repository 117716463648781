<template>
  <b-modal :id="id" centered size="xl" title="New Form" no-close-on-backdrop footer-class="d-block" @hidden="clear()">
    <!-- Content -->
    <template #default>
      <b-container>

        <validation-observer ref="observer" v-slot="{ handleSubmit }" tag="div">
          <form ref="form" @submit.stop.prevent="handleSubmit(create)">
            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="name" name="Name" rules="required">
                  <b-form-group label="Name" label-for="name-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="name-input" v-model="form.name" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider v-slot="validationContext" vid="type" name="Type" rules="required">
                  <b-form-group label="Type (determines application questions)" label-for="type-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                    <v-select id="type-input"
                              v-model="form.type"
                              label="text"
                              :reduce="type => type.value"
                              :options="options.types"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              placeholder="Type"
                              class="form-control-plaintext w-100"/>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </form>
        </validation-observer>
      </b-container>
    </template>

    <!-- Footer -->
    <template #modal-footer>
      <b-row>
        <b-col align-self="center">
          <b-checkbox v-model="state.redirect" :checked="true" :unchecked-value="false">
            <small>Redirect to page on submit</small>
          </b-checkbox>
        </b-col>
        <b-col align-self="center" class="text-right">
          <b-button type="reset" class="mr-1" @click="clear()">Clear</b-button>
          <b-button type="submit" variant="primary" @click="$refs.observer.handleSubmit(create)">Submit</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { uuid } from 'vue-uuid';
  import vSelect from 'vue-select'
  import notify from '@/mixins/notify.mixin'
  import { createForm } from '@/graphql/mutations';
  import slugify from 'slugify';

  export default {
    name: 'FormModal',
    components: {
      vSelect
    },
    mixins: [ notify ],
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        form: {
          name: null,
          type: null
        },
        options: {
          types: [
            { text: 'Jazz - Instrumental', value: 'jazzInstrumental' },
            { text: 'Jazz - Vocal', value: 'jazzVocal' },
            { text: 'Strings', value: 'strings' },
            { text: 'Voice', value: 'voice' },
            { text: 'Wind And  Percussion', value: 'windAndPercussion' },
            { text: 'Other', value: 'other' }
          ]
        },
        state: {
          redirect: false
        },
      }
    },
    methods: {
      async create() {
        try {
          const id = uuid.v4();
          const slug = slugify(this.form.name, { lower: true })
          const formInput = {
            id: id,
            slug: slug,
            name: this.form.name,
            type: this.form.type,
            state: {
              enabled: true
            }
          }
          const response = await API.graphql(graphqlOperation(createForm, { input: formInput }));
          const form = response.data.createForm

          if(this.state.redirect) {
            await this.$router.push({name: 'management-form', params: { id: id, slug: slug }})
          }
          else {
            this.$emit('created', form)
            this.$root.$emit('bv::toggle::modal', this.id)
            this.notify({ title: 'Success', text: 'Form was successfully created', icon: 'fas fa-server', variant: 'success' });
          }
        }
        catch (err) {
          console.error(err)
          this.notify({ title: 'Error', text: 'Form failed to create', icon: 'fas fa-server', variant: 'danger'});
        }
      },
      clear() {
        this.form = {
          name: null,
          type: null
        }
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    }
  }
</script>

<style scoped>

</style>
