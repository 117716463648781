<template>
  <page-layout ref="layout">

    <template #actions="{ }">
      <can do="create" on="management-forms">
        <b-button v-b-tooltip="'Create'" v-b-modal="'form-modal'" variant="transparent" size="sm">
          <font-awesome-icon icon="fa-solid fa-plus" />
        </b-button>
        <form-modal id="form-modal" @created="addForm"></form-modal>
      </can>
    </template>

    <template #dropdown-options="{ }">
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <table-layout ref="table-layout"
                    :items="table.items"
                    :fields="table.fields"
                    :sorting="table.sorting"
                    :loading="table.loading"
                    :subscriptions="table.subscriptions"
                    :is-searchable="true"
                    :func-delete="deleteForm"
                    :export-exclude-fields="[
                        'id',
                        'slug',
                        'type',
                        'state.enabled',
                    ]"
                    @mounted="table = $event"
                    @updated="table = $event">

        <template #overlay>
          <overlay-loading :items="[
            { state: table.loading, desc: 'Loading Forms', loaded: table.items.length},
            { state: state.loading, desc: 'Rendering Template'},
          ]" />
        </template>

        <!-- Column: Name -->
        <template #cell(name)="{data}">
          <b-media vertical-align="center" no-body>
            <b-media-aside>
              <b-avatar v-if="hasCustomAvatar(data.item.avatar)" v-b-modal.avatar-modal variant="primary" size="2.5em" badge-variant="white">
                <b-img fluid fluid-grow :src="data.item.avatar.src" :alt="data.item.avatar.name"></b-img>
              </b-avatar>
              <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="2.5em" badge-variant="white">
                <font-awesome-icon :icon="icon"></font-awesome-icon>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="align-self-center">
              <b-link :to="{ name: 'management-form', params: { id: data.item.id, slug: data.item.slug } }" class="font-weight-bold d-block text-nowrap">
                {{ data.item.name }}
              </b-link>
            </b-media-body>
          </b-media>
        </template>

        <!-- Column: State - Enabled -->
        <template #cell(state.enabled)="{data}">
          <b-badge pill :variant="`light-${data.item.state.enabled ? 'success' : 'danger'}`" class="text-capitalize">
            {{ data.item.state.enabled }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(row-options)="{data}">
          <b-dropdown-item :to="{ name: 'management-form', params: { id: data.item.id, slug: data.item.slug } }"
                           class="table-row-option-view">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">View</span>
          </b-dropdown-item>

          <template v-if="$can('update', 'management-forms') || $can('delete', 'management-forms')">
            <b-dropdown-divider/>
            <can do="update" on="management-forms">
              <b-dropdown-item class="table-row-option-enable"
                               @click="updateForm(data.item)">
                <font-awesome-icon :icon="['fas', data.item.state.enabled ? 'toggle-off' : 'toggle-on']"></font-awesome-icon>
                <span class="align-middle ml-50">{{ data.item.state.enabled ? 'Disable' : 'Enable' }}</span>
              </b-dropdown-item>
            </can>
            <can do="delete" on="management-forms">
              <b-dropdown-item class="table-row-option-delete"
                               @click="$refs.layout.confirmDelete(data.item, deleteForm, cascadeConfirmDeleteOptions)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </can>
          </template>

        </template>
      </table-layout>
    </template>

    <template #debug>
      <debug :collapsed="true">{{ table }}</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import TableLayout from '@/components/TableLayout.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import FormModal from '@/views/management/forms/FormModal.vue'
import avatar from '@/mixins/avatar.mixin';
import notify from '@/mixins/notify.mixin';
import print from '@/mixins/print.mixin';
import { API, graphqlOperation } from 'aws-amplify';
import { listForms, updateForm, onCreateForm, onUpdateForm, onDeleteForm} from '@/graphql/queries/forms';
import { cascadeDeleteForm, cascadeConfirmDeleteOptions} from '@/graphql/cascade/form';

export default {
  name: 'Zones',
  components: {
    OverlayLoading,
    PageLayout,
    TableLayout,
    FormModal
  },
  mixins: [ avatar, notify, print ],
  data() {
    return {
      table: {
        items: [],
        loading: true,
        fields: [
          {
            key: 'name',
            label: 'Name',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'instruments.items.length',
            label: 'Instruments',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'state.enabled',
            label: 'Enabled',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'row-options',
            label: '',
            sortable: false,
            visible: true,
            tdClass: ['align-middle', 'table-row-options']
          },
        ],
        sorting: {
          by: 'name',
          desc: false
        },
        subscriptions: {
          onCreate: null,
          onUpdate: null,
          onDelete: null
        }
      },
      icon: 'fa-solid fa-file-lines',
      debug: { },
      cascadeConfirmDeleteOptions
    }
  },
  async created() {
    await this.listForms();
    this.onCreateForm();
    this.onUpdateForm();
    this.onDeleteForm();
  },
  mounted() {
    this.$refs.layout.state.loading = false
  },
  methods: {
    /** Table Data **/
    async listForms() {
      this.table.loading = true
      const response = await API.graphql(graphqlOperation(listForms));
      this.table.items = response.data.listForms.items;

      await this.$nextTick(() => {
        setTimeout( () => { this.table.loading = false }, 500);
      })
    },
    async addForm(form) {
      this.table.loading = true
      this.table.items.push(form)
      this.table.loading = false
    },
    async updateForm(form) {
      try {
        form.state.enabled = !form.state.enabled
        await API.graphql(graphqlOperation(updateForm, { input: { id: form.id, state: form.state } } ));
        this.notify({ title: 'Success', text: 'Form was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch (err) {
        console.error(err)
        this.notify({ title: 'Error', text: 'Zone failed to update', icon: this.icon, variant: 'danger'});
      }
    },
    async deleteForm(form, swalCallback) {
      try {
        this.table.loading = true
        await this.cascadeDeleteForm(form.id, swalCallback)
        this.table.items = this.table.items.filter(item => item.id !== form.id);
        this.notify({ title: 'Success', text: 'Form was successfully deleted', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Form failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for Swal
      }
      finally {
        this.table.loading = false
      }
    },
    cascadeDeleteForm,

    /** Subscriptions **/
    onCreateForm() {
      this.table.subscriptions.onCreate = API.graphql(graphqlOperation(onCreateForm)).subscribe((sourceData) => {
        const form = sourceData.value.data.onCreateForm
        if (form && !this.table.items.map(item => item.id).includes(form.id)) {
          this.syncNotification()
          this.table.loading = true
          this.table.items = [form, ...this.table.items];
          this.table.loading = false
        }
      });
    },
    onUpdateForm() {
      this.table.subscriptions.onUpdate = API.graphql(graphqlOperation(onUpdateForm)).subscribe((sourceData) => {
        const form = sourceData.value.data.onUpdateForm
        if (form && this.table.items.map(item => item.id).includes(form.id)) {
          this.syncNotification()
          this.table.loading = true
          const index = this.table.items.findIndex(item => item.id === form.id)
          if(index > -1) {
            this.table.items.splice(index, 1, form)
          }
          this.table.loading = false
        }
      });
    },
    onDeleteForm() {
      this.table.subscriptions.onDelete = API.graphql(graphqlOperation(onDeleteForm)).subscribe((sourceData) => {
        const form = sourceData.value.data.onDeleteForm
        if(form && this.table.items.map(item => item.id).includes(form.id)) {
          this.syncNotification()
          this.table.loading = true
          this.table.items = this.table.items.filter(item => item.id !== form.id);
          this.table.loading = false
        }
      });
    },

    /** Util **/
    async refresh() {
      this.table.loading = true
      await this.listForms()
    },
  }
}
</script>

<style lang="scss">

</style>
